<template>
  <div>
    <a-modal
      :visible="visible.modalEditMarital"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditMarital"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        MARITAL
      </h3>
      <p>
        Apakah anda yakin mau memperbarui tunjangan marital ini? Setelah
        diperbarui, data lama marital ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="maritalSearch"
          @keyup="searchMarital"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Status Marital...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.marital">
          <a-button size="large" @click.prevent="toEditMarital" icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        v-if="edit.marital"
        :columns="maritalColumns"
        :data-source="maritalDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeMarital"
        bordered
      >
        <div slot="keluarga" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditMaritalChange(
                  e.target.value,
                  record.key,
                  'keluarga'
                )
            "
          />
        </div>
        <div slot="kepalaKeluarga" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditMaritalChange(
                  e.target.value,
                  record.key,
                  'kepalaKeluarga'
                )
            "
          />
        </div>
        <div slot="lakiLaki" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditMaritalChange(
                  e.target.value,
                  record.key,
                  'lakiLaki'
                )
            "
          />
        </div>
        <div slot="perempuan" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditMaritalChange(
                  e.target.value,
                  record.key,
                  'perempuan'
                )
            "
          />
        </div>
      </a-table>
      <a-table
        v-else
        :columns="maritalColumns"
        :data-source="maritalDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeMarital"
        bordered
      >
      </a-table>
    </div>
  </div>
</template>

<script>
const maritalColumns = [
  {
    title: 'Status Marital',
    dataIndex: 'statusMarital',
    key: 'statusMarital',
    width: 150,
    fixed: 'left',
    align: 'center',
    scopedSlots: { customRender: 'statusMarital' },
    sorter: true,
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    key: 'keterangan',
    scopedSlots: { customRender: 'keterangan' },
    width: 200,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Tunjangan',
    dataIndex: 'tunjangan',
    key: 'tunjangan',
    scopedSlots: { customRender: 'tunjangan' },
    align: 'center',
    children: [
      {
        title: 'Keluarga (%)',
        dataIndex: 'keluarga',
        key: 'keluarga',
        scopedSlots: { customRender: 'keluarga' },
        width: 200,
        align: 'center',
      },
      {
        title: 'Kepala Keluarga (%)',
        dataIndex: 'kepalaKeluarga',
        key: 'kepalaKeluarga',
        scopedSlots: { customRender: 'kepalaKeluarga' },
        width: 200,
        align: 'center',
      },
      {
        title: 'Kesehatan',
        dataIndex: 'kesehatan',
        key: 'kesehatan',
        scopedSlots: { customRender: 'kesehatan' },
        align: 'center',
        children: [
          {
            title: 'Laki-Laki (%)',
            dataIndex: 'lakiLaki',
            key: 'lakiLaki',
            scopedSlots: { customRender: 'lakiLaki' },
            width: 200,
            align: 'center',
          },
          {
            title: 'Perempuan (%)',
            dataIndex: 'perempuan',
            key: 'perempuan',
            scopedSlots: { customRender: 'perempuan' },
            width: 200,
            align: 'center',
          },
        ],
      },
    ],
  },
]

export default {
  name: 'master-marital',
  data() {
    return {
      maritalColumns,
      maritalDataTable: [],
      savedMaritalData: [],
      visible: {
        modalEditMarital: false,
      },
      loading: {
        table: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      maritalPage: 'all',
      maritalSearch: '',
      maritalOrder: '',
      maritalSortBy: '',
      edit: {
        marital: false,
      },
    }
  },
  methods: {
    handleEditMaritalChange(value, key, column) {
      const newData = [...this.maritalDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedMaritalData = newData
      }
    },
    handleTableChangeMarital(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'statusMarital') {
        sortBy = 'status_marital'
      } else {
        sortBy = ''
      }
      this.fetchDataMarital({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.cancel = boolean
    },
    searchMarital() {
      if (this.maritalSearch.length > 2) {
        this.fetchDataMarital({
          search: this.maritalSearch,
        })
      } else {
        this.fetchDataMarital({
          search: this.maritalSearch,
        })
      }
    },
    fetchDataMarital(
      params = {
        order: this.maritalOrder,
        sortBy: this.maritalSortBy,
        search: this.maritalSearch,
        page: this.maritalPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminKeuangan/GET_MARITAL_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.maritalDataTable = res.data.map((row) => {
            return {
              key: row.id,
              statusMarital: row.status_marital,
              keterangan: row.keterangan,
              keluarga: row.tunjangan_keluarga || 0,
              kepalaKeluarga: row.tunjangan_kepala_keluarga || 0,
              lakiLaki: row.laki_laki || 0,
              perempuan: row.perempuan || 0,
            }
          })
          this.savedMaritalData = this.maritalDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalEditMarital = false
    },
    toEditMarital() {
      this.edit.marital = true
    },
    saveEdit() {
      this.visible.modalEditMarital = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataMarital()
      setTimeout(() => {
        this.edit.marital = false
        this.handleAllLoading(false)
      })
    },
    validateMaritalDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].keluarga === '' ||
          data[i].keluarga === '-' ||
          data[i].keluarga === null
        ) {
          data[i].keluarga = 0
        } else if (isNaN(Number(data[i].keluarga))) {
          isInvalid = true
          message = `Maaf tunjangan keluarga ke-${i + 1}, harus angka!`
          break
        }
        if (
          data[i].kepalaKeluarga === '' ||
          data[i].kepalaKeluarga === '-' ||
          data[i].kepalaKeluarga === null
        ) {
          data[i].kepalaKeluarga = 0
        } else if (isNaN(Number(data[i].kepalaKeluarga))) {
          isInvalid = true
          message = `Maaf tunjangan kepala keluarga ke-${i + 1}, harus angka!`
          break
        }
        if (
          data[i].lakiLaki === '' ||
          data[i].lakiLaki === '-' ||
          data[i].lakiLaki === null
        ) {
          data[i].lakiLaki = 0
        } else if (isNaN(Number(data[i].lakiLaki))) {
          isInvalid = true
          message = `Maaf tunjangan kesehatan untuk laki-laki ke-${i + 1}, harus angka!`
          break
        }
        if (
          data[i].perempuan === '' ||
          data[i].perempuan === '-' ||
          data[i].perempuan === null
        ) {
          data[i].perempuan = 0
        } else if (isNaN(Number(data[i].perempuan))) {
          isInvalid = true
          message = `Maaf tunjangan kesehatan untuk perempuan ke-${i + 1}, harus angka!`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditMarital() {
      const newData = [...this.savedMaritalData]
      if (newData.length) {
        const validation = this.validateMaritalDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditMarital = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              status_marital: row.statusMarital,
              keterangan: row.keterangan,
              tunjangan_keluarga: row.keluarga,
              tunjangan_kepala_keluarga: row.kepalaKeluarga,
              laki_laki: row.lakiLaki,
              perempuan: row.perempuan,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminKeuangan/UPDATE_MARITAL', {
              data: target,
            })
            .then((res) => {
              setTimeout(() => {
                this.fetchDataMarital()
                this.edit.marital = false
                this.visible.modalEditMarital = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Marital berhasil diperbarui',
                  })
                } else {
                  this.$notification.error(
                    {
                      message: 'Gagal',
                      description: 'Marital tidak berhasil diperbarui',
                    },
                    1000,
                  )
                }
              })
            })
        }
      } else {
        this.edit.marital = false
        this.visible.modalEditMarital = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Marital tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataMarital()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
